import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsSave } from "react-icons/bs";
import { Label } from "../../Common/Label/Label";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import WebService from "../../../Services/WebService";
import { toast } from "react-toastify";
import { use } from "i18next";
import { MdClose } from "react-icons/md";
import { set } from "date-fns";
import HelperService from "../../../Services/HelperService";
import { Trash } from "react-bootstrap-icons";
import { el, is } from "date-fns/locale";

const BusinessWorkingHours = (props: any) => {
  const { t } = useTranslation();
  const [isEnable, setIsEnable] = useState(false);
  const [days, setDays] = useState<any[]>([]);
  const [errorFlag, setErrorFlag] = useState(false);
  const [daysError, setDaysError] = useState("");
  const [startTime, setStartTime] = useState<Date>(
    new Date(new Date().setHours(10, 0, 0))
  );
  const [endTime, setEndTime] = useState(
    new Date(new Date().setHours(19, 0, 0))
  );
  const [holidayDates, setHolidayDates] = useState<Date[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [curDate, setCurDate] = useState<Date | null>(null);
  const [messages, setMessages] = useState({
    message_for_before_time: "",
    message_for_after_time: "",
    message_for_holiday: "",
  });

  useEffect(() => {
    if (props?.data?.agent_chat_timing) {
      setIsEnable(props?.data?.agent_chat_timing?.is_enable);
      setStartTime(parseTime(props?.data?.agent_chat_timing?.start_time));
      setEndTime(parseTime(props?.data?.agent_chat_timing?.end_time));
      setDays(props?.data?.agent_chat_timing?.days);
      setMessages(props?.data?.agent_chat_timing?.messages);
      setHolidayDates(
        props?.data?.agent_chat_timing?.holiday_dates?.map(
          (date: string) => new Date(date)
        )
      );
      setErrorFlag(false);
    }
  }, [props?.data]);

  const parseTime = (timeStr: string): Date => {
    const [hours, minutes, seconds] = timeStr?.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds);
    return date;
  };

  const handleAddClick = () => {
    setCurDate(null);
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setCurDate(null);
    setShowAddModal(false);
  };

  const handleAddHolidayDate = () => {
    if (!curDate) {
      toast.error("Please select a valid date.");
      return;
    }

    let isSelected = holidayDates.some(
      (d) => d.getTime() === curDate.getTime()
    );
    if (isSelected) {
      toast.error("Date already selected.");
      return;
    }
    setHolidayDates((prevDates) => {
      return [...prevDates, curDate];
    });
    closeAddModal();
  };

  const handleRemoveHolidayDate = (date: Date) => {
    setHolidayDates((prevDates) =>
      prevDates.filter((d) => d.toDateString() !== date.toDateString())
    );
  };

  const handleChangeMessage = (e: any) => {
    setMessages({
      ...messages,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    if (days.length === 0) {
      setDaysError("Please select days");
      return;
    }
    let flag = false;

    Object.keys(messages).map((key) => {
      const eventKey = key as keyof typeof messages;
      if (messages[eventKey] === "" || messages[eventKey]?.trim() === "") {
        flag = true;
      }
    });

    if (flag) {
      setErrorFlag(true);
      return;
    } else {
      setErrorFlag(false);
    }

    let obj = {
      agent_chat_timing: {
        is_enable: isEnable,
        days: days,
        start_time: moment(startTime).format("HH:mm:ss"),
        end_time: moment(endTime).format("HH:mm:ss"),
        messages: messages,
        holiday_dates: holidayDates.map((date) =>
          moment(date).format("YYYY-MM-DD")
        ),
      },
    };

    WebService.addLoader("save-wrk-hrs");
    return WebService.postAPI({
      action: `user-website/agent-chat-timing`,
      body: obj,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res.message);
        WebService.removeLoader("save-wrk-hrs");
      })
      .catch((error) => {
        WebService.removeLoader("save-wrk-hrs");
        return error;
      });
  };

  const handleSaveDisable = () => {
    let obj = {
      agent_chat_timing: {
        is_enable: false,
        days: days,
        start_time: moment(startTime).format("HH:mm:ss"),
        end_time: moment(endTime).format("HH:mm:ss"),
        messages: messages,
        holiday_dates: holidayDates.map((date) =>
          moment(date).format("YYYY-MM-DD")
        ),
      },
    };

    WebService.addLoader("save-wrk-hrs");
    return WebService.postAPI({
      action: `user-website/agent-chat-timing`,
      body: obj,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res.message);
        WebService.removeLoader("save-wrk-hrs");
      })
      .catch((error) => {
        WebService.removeLoader("save-wrk-hrs");
        return error;
      });
  };

  return (
    <>
      <Card className="border-0">
        <Form className="wmx-form-style">
          <Card.Body className="px-4">
            <div className="d-flex gap-2 mb-2 mt-2 align-items-center">
              <span className="font-500">
                <span className="font-14 fw-bold">Is Enable</span>
              </span>
              <Form.Check
                type="switch"
                id="custom-switch"
                label=" "
                className="cursor-pointer"
                checked={isEnable}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsEnable(e.target.checked);
                  } else {
                    setIsEnable(e.target.checked);
                    handleSaveDisable();
                  }
                }}
              />
            </div>
            {isEnable && (
              <>
                <div className=" mb-2 font-14 fw-bold">Select Working Days</div>
                <div key={`inline"`} className="mb-3">
                  <Form.Check
                    inline
                    label={t("AutomationList.Sun")}
                    name="days"
                    value="SUNDAY"
                    type="checkbox"
                    id={`inline-1`}
                    checked={days.includes("SUNDAY")}
                    onChange={(e: any) => {
                      var dya1 = [...days];
                      if (e.target.checked) {
                        dya1.push(e.target.value);
                      } else {
                        if (dya1.indexOf(e.target.value) !== -1) {
                          dya1.splice(dya1.indexOf(e.target.value), 1);
                        }
                      }
                      setDays(dya1);
                      setDaysError("");
                    }}
                  />
                  <Form.Check
                    inline
                    label={t("AutomationList.Mon")}
                    name="days"
                    value="MONDAY"
                    type="checkbox"
                    id={`inline-2`}
                    checked={days.includes("MONDAY")}
                    onChange={(e: any) => {
                      var dya1 = [...days];
                      if (e.target.checked) {
                        dya1.push(e.target.value);
                      } else {
                        if (dya1.indexOf(e.target.value) !== -1) {
                          dya1.splice(dya1.indexOf(e.target.value), 1);
                        }
                      }
                      setDays(dya1);
                      setDaysError("");
                    }}
                  />
                  <Form.Check
                    inline
                    label={t("AutomationList.Tue")}
                    name="days"
                    value="TUESDAY"
                    type="checkbox"
                    id={`inline-3`}
                    checked={days.includes("TUESDAY")}
                    onChange={(e: any) => {
                      var dya1 = [...days];
                      if (e.target.checked) {
                        dya1.push(e.target.value);
                      } else {
                        if (dya1.indexOf(e.target.value) !== -1) {
                          dya1.splice(dya1.indexOf(e.target.value), 1);
                        }
                      }
                      setDays(dya1);
                      setDaysError("");
                    }}
                  />
                  <Form.Check
                    inline
                    label={t("AutomationList.Wed")}
                    name="days"
                    type="checkbox"
                    value="WEDNESDAY"
                    id={`inline-4`}
                    checked={days.includes("WEDNESDAY")}
                    onChange={(e: any) => {
                      var dya1 = [...days];
                      if (e.target.checked) {
                        dya1.push(e.target.value);
                      } else {
                        if (dya1.indexOf(e.target.value) !== -1) {
                          dya1.splice(dya1.indexOf(e.target.value), 1);
                        }
                      }
                      setDays(dya1);
                      setDaysError("");
                    }}
                  />
                  <Form.Check
                    inline
                    label={t("AutomationList.Thu")}
                    name="days"
                    type="checkbox"
                    value="THURSDAY"
                    id={`inline-5`}
                    checked={days.includes("THURSDAY")}
                    onChange={(e: any) => {
                      var dya1 = [...days];
                      if (e.target.checked) {
                        dya1.push(e.target.value);
                      } else {
                        if (dya1.indexOf(e.target.value) !== -1) {
                          dya1.splice(dya1.indexOf(e.target.value), 1);
                        }
                      }
                      setDays(dya1);
                      setDaysError("");
                    }}
                  />
                  <Form.Check
                    inline
                    label={t("AutomationList.Fri")}
                    name="days"
                    type="checkbox"
                    value="FRIDAY"
                    id={`inline-6`}
                    checked={days.includes("FRIDAY")}
                    onChange={(e: any) => {
                      var dya1 = [...days];
                      if (e.target.checked) {
                        dya1.push(e.target.value);
                      } else {
                        if (dya1.indexOf(e.target.value) !== -1) {
                          dya1.splice(dya1.indexOf(e.target.value), 1);
                        }
                      }
                      setDays(dya1);
                      setDaysError("");
                    }}
                  />
                  <Form.Check
                    inline
                    label={t("AutomationList.Sat")}
                    name="days"
                    type="checkbox"
                    value="SATURDAY"
                    id={`inline-7`}
                    checked={days.includes("SATURDAY")}
                    onChange={(e: any) => {
                      var dya1 = [...days];
                      if (e.target.checked) {
                        dya1.push(e.target.value);
                      } else {
                        if (dya1.indexOf(e.target.value) !== -1) {
                          dya1.splice(dya1.indexOf(e.target.value), 1);
                        }
                      }
                      setDays(dya1);
                      setDaysError("");
                    }}
                  />
                  {daysError !== "" && (
                    <Label title={daysError} modeError={true} />
                  )}
                </div>
                <div className=" mb-2 font-14 fw-bold">
                  Select Working Hours
                </div>
                <Row>
                  <Col md={2} className="font-14">
                    From :
                  </Col>
                  <Col md={3}>
                    <ReactDatePicker
                      className="form-control"
                      selected={startTime}
                      onChange={(date: Date) => {
                        setStartTime(date);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="hh:mm:ss a"
                    />
                  </Col>
                  <Col md={2} className="font-14">
                    To :
                  </Col>
                  <Col md={3}>
                    <ReactDatePicker
                      className="form-control"
                      selected={endTime}
                      onChange={(date: Date) => {
                        setEndTime(date);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="hh:mm:ss a"
                    />
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col md={12}>
                    <div className="mb-3">
                      <FloatingLabel
                        label="Message for before time"
                        className="mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          rows={3}
                          style={{ height: "100%" }}
                          name="message_for_before_time"
                          value={messages.message_for_before_time}
                          onChange={(e: any) => {
                            handleChangeMessage(e);
                          }}
                          placeholder="Message for before time"
                        />
                        {errorFlag &&
                          messages.message_for_before_time === "" && (
                            <Label
                              title={"Please enter message for before time"}
                              modeError={true}
                            />
                          )}
                      </FloatingLabel>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <FloatingLabel
                        label="Message for after time"
                        className="mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          rows={3}
                          style={{ height: "100%" }}
                          name="message_for_after_time"
                          value={messages.message_for_after_time}
                          onChange={(e: any) => {
                            handleChangeMessage(e);
                          }}
                          placeholder="Message for after time"
                        />
                        {errorFlag &&
                          messages.message_for_after_time === "" && (
                            <Label
                              title={"Please enter message for after time"}
                              modeError={true}
                            />
                          )}
                      </FloatingLabel>
                    </div>
                  </Col>
                </Row>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="mb-2 font-14 fw-bold">Select Holidays</div>
                  <Button className="btn-wmx btn-sm" onClick={handleAddClick}>
                    {t("Common.Add")}
                  </Button>
                </div>
                <Row className="mb-3">
                  <Col md={12} className="">
                    {holidayDates.map((date, index) => (
                      <li
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {HelperService.getFormatedDateForDetail(
                          date,
                          "DD-MM-YYYY"
                        )}
                        <Trash
                          onClick={() => handleRemoveHolidayDate(date)}
                          className="me-2 cursor-pointer text-danger"
                        />
                      </li>
                    ))}
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col md={12}>
                    <div className="mb-3">
                      <FloatingLabel
                        label="Message for holiday"
                        className="mb-3"
                      >
                        <Form.Control
                          as="textarea"
                          rows={3}
                          style={{ height: "100%" }}
                          name="message_for_holiday"
                          value={messages.message_for_holiday}
                          onChange={(e: any) => {
                            handleChangeMessage(e);
                          }}
                          placeholder="Message for holiday"
                        />
                        {errorFlag && messages.message_for_holiday === "" && (
                          <Label
                            title={"Please enter message for holiday"}
                            modeError={true}
                          />
                        )}
                      </FloatingLabel>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>
          {isEnable && (
            <>
              <Card.Footer className="bg-white text-center rounded-bottom-3 border-light py-3">
                <Button
                  className="btn-wmx btn-lg"
                  id="save-wrk-hrs"
                  onClick={handleSave}
                >
                  <BsSave size={16} className="me-1" /> {t("Common.Save")}
                </Button>
              </Card.Footer>
            </>
          )}
        </Form>
      </Card>

      <Offcanvas
        show={showAddModal}
        onHide={closeAddModal}
        placement="end"
        className="righSide-modal size-md"
      >
        <Form className="wmx-form-style">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t("Add Holiday")}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Row>
              <div className="mb-3">
                <ReactDatePicker
                  selected={curDate}
                  onChange={(date: Date) => setCurDate(date)}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select Date"
                  className="form-control"
                />
              </div>
            </Row>
          </Offcanvas.Body>
          <div className="offcanvas-footer">
            <Button className="btn btn-wmx-gray" onClick={closeAddModal}>
              <MdClose size={18} className="icon" /> {t("Common.Close")}
            </Button>
            <Button className="btn  btn-wmx" onClick={handleAddHolidayDate}>
              {t("Common.Add")}
            </Button>
          </div>
          .
        </Form>
      </Offcanvas>
    </>
  );
};

export default BusinessWorkingHours;
